<script lang="ts" setup>
import { computed } from 'vue'
import type User from '~/src/Domain/Identity/User'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useUserNavigation from '~/src/UserInterface/Identity/composables/useUserNavigation'

const props = defineProps<{
  user: User
  organisation?: Organisation
  hasEngagement: boolean
  closeButtonTo?: { name: string, params?: Record<string, string | string[]> } | string
}>()

const { userSettingsProfileLocalePath, userSettingsExportsLocalePath } = useUserNavigation(computed(() => props.user))
</script>

<template>
  <ui-navbar
    id="topbar"
    toggleable="md"
    class="navbar-light"
    :container="false"
  >
    <ui-container fluid>
      <div class="row w-100">
        <div class="col-12 col-xxl-10 offset-xxl-1 d-flex align-items-center">
          <ui-navbar-brand class="border-end pe-5 me-5">
            <span class="text-black"> {{ user.firstName }} {{ user.lastName }} </span>
          </ui-navbar-brand>

          <ui-nav tabs>
            <ui-nav-item :to="userSettingsProfileLocalePath">
              {{ $t('components.profile.navbar_top.nav_profile') }}
            </ui-nav-item>
            <ui-nav-item
              v-if="organisation !== undefined"
              :to="userSettingsExportsLocalePath"
            >
              {{ $t('components.profile.navbar_top.nav_exports') }}
            </ui-nav-item>
          </ui-nav>

          <div
            v-if="organisation !== undefined"
            class="ms-auto d-flex align-items-center"
          >
            <notifications-button />
            <identity-avatar-dropdown :user />
            <ui-button-close
              v-if="closeButtonTo"
              :to="closeButtonTo"
            />
          </div>
          <identity-profile-navbar-dropdown v-if="organisation === undefined" />
        </div>
      </div>
    </ui-container>
  </ui-navbar>
</template>
