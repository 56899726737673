<script lang="ts" setup>
import { ref, toValue, watch } from 'vue'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useEngagementNavigation
  from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import profileSettingsPreviousRouteStore from '~/src/UserInterface/Identity/store/ProfileSettingsPreviousRouteStore'
import useOrganisationNavigation from '~/src/UserInterface/Organisation/composables/useOrganisationNavigation'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const { getUser } = useUserState()
const user = getUser()
const { hasOrganisation, getOrganisation } = useOrganisationState()
const organisation = toValue(hasOrganisation) ? getOrganisation() : undefined
const { hasCurrentEngagement, getCurrentEngagement, hasCurrentPhase, getCurrentPhase } = useEngagementState()

const { getLocalePath } = useNavigation()
const store = profileSettingsPreviousRouteStore()
const closeButtonTo = ref<string | undefined>('/')
watch(
  [
    () => store.previousRoute,
    hasOrganisation,
    hasCurrentEngagement,
    hasCurrentPhase,
  ],
  ([
    newPreviousRoute,
    newHasOrganisation,
    newHasCurrentEngagement,
    newHasCurrentPhase,
  ],
  ) => {
    if (newPreviousRoute !== undefined) {
      closeButtonTo.value = getLocalePath(toValue(newPreviousRoute))
    } else if (newHasOrganisation && newHasCurrentEngagement && newHasCurrentPhase) {
      const { engagementDashboardLocalePath } = useEngagementNavigation(toValue(getOrganisation()), toValue(getCurrentEngagement()), toValue(getCurrentPhase()))
      closeButtonTo.value = engagementDashboardLocalePath.value
    } else if (newHasOrganisation) {
      const { organisationSettingsEngagementsLocalePath } = useOrganisationNavigation(toValue(getOrganisation()))
      closeButtonTo.value = organisationSettingsEngagementsLocalePath
    } else {
      closeButtonTo.value = undefined
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="main-content d-flex flex-column">
    <identity-profile-navbar-top
      :user
      :organisation
      :has-engagement="hasCurrentEngagement"
      :close-button-to="closeButtonTo"
    />

    <div class="flex-grow-1">
      <slot />
    </div>

    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
