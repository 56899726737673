<script lang="ts" setup>
import useIdentityNavigation from '~/src/UserInterface/Identity/composables/useIdentityNavigation'

const { identityLogoutPageLocalePath } = useIdentityNavigation()
</script>

<template>
  <ui-dropdown
    no-caret
    variant="white"
  >
    <template #button-content>
      <ui-icon icon="lock" />
    </template>

    <ui-dropdown-item-button :to="identityLogoutPageLocalePath">
      {{ $t('components.profile.navbar_dropdown.btn_logout') }}
    </ui-dropdown-item-button>
  </ui-dropdown>
</template>

<style lang="scss" scoped>
.bi-lock {
  color: $blue-4;
}
</style>
